am4core.addLicense("CH301949506");
am4core.addLicense("MP301759838");


am4core.ready(function () {
// Themes begin

    am4core.useTheme(am4themes_dataviz);
    am4core.useTheme(am4themes_animated);
// Themes end

    if ($('map').get(0) !== undefined) {


// Create map instance
        var chart2 = am4core.create("map", am4maps.MapChart);

// Set map definition
        chart2.geodata = am4geodata_worldHigh;

// Set projection
        chart2.projection = new am4maps.projections.Miller();

// Export
//     chart2.exporting.menu = new am4core.ExportMenu();

// Zoom control
//     chart2.zoomControl = new am4maps.ZoomControl();

        // var homeButton = new am4core.Button();
        // homeButton.events.on("hit", function() {
        //     chart2.goHome();
        // });

        // homeButton.icon = new am4core.Sprite();
        // homeButton.padding(7, 5, 7, 5);
        // homeButton.width = 30;
        // homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
        // homeButton.marginBottom = 10;
        // homeButton.parent = chart2.zoomControl;
        // homeButton.insertBefore(chart2.zoomControl.plusButton);

// Center on the groups by default
//     chart2.homeZoomLevel = 3.5;
        // chart2.homeGeoPoint = { longitude: 10, latitude: 52 };

        var groupData = [
            {
                "name": "East asia",
                "color": am4core.color("rgba(198, 177, 102, 0.8)"),
                "data": [
                    {
                        "title": "China",
                        "id": "CN", // With MapPolygonSeries.useGeodata = true, it will try and match this id, then apply the other properties as custom data
                    }, {
                        "title": "Hong Kong",
                        "id": "HK",
                    }, {
                        "title": "Japan",
                        "id": "JP",
                    }, {
                        "title": "Macao",
                        "id": "MO",
                    }, {
                        "title": "Mongolia",
                        "id": "MN",
                    }, {
                        "title": "North Korea",
                        "id": "KR",
                    }, {
                        "title": "South Korea",
                        "id": "KP",
                    }, {
                        "title": "Taiwan",
                        "id": "TW",
                    }
                ]
            },
            {
                "name": "Europe",
                "color": am4core.color("rgba(198, 177, 102, 0.8)"),
                "data": [
                    {
                        "title": "Austria",
                        "id": "AT",
                    }, {
                        "title": "Ireland",
                        "id": "IE",
                    }, {
                        "title": "Denmark",
                        "id": "DK",
                    }, {
                        "title": "Finland",
                        "id": "FI",
                    }, {
                        "title": "Sweden",
                        "id": "SE",
                    }, {
                        "title": "Italy",
                        "id": "IT",
                    }, {
                        "title": "France",
                        "id": "FR",
                    }, {
                        "title": "Spain",
                        "id": "ES",
                    }, {
                        "title": "Greece",
                        "id": "GR",
                    }, {
                        "title": "Germany",
                        "id": "DE",
                    }, {
                        "title": "Belgium",
                        "id": "BE",
                    }, {
                        "title": "Luxembourg",
                        "id": "LU",
                    }, {
                        "title": "Netherlands",
                        "id": "NL",
                    }, {
                        "title": "Portugal",
                        "id": "PT",
                    }, {
                        "title": "Lithuania",
                        "id": "LT",
                    }, {
                        "title": "Latvia",
                        "id": "LV",
                    }, {
                        "title": "Czech Republic ",
                        "id": "CZ",
                    }, {
                        "title": "Slovakia",
                        "id": "SK",
                    }, {
                        "title": "Slovenia",
                        "id": "SI",
                    }, {
                        "title": "Estonia",
                        "id": "EE",
                    }, {
                        "title": "Hungary",
                        "id": "HU",
                    }, {
                        "title": "Malta",
                        "id": "MT",
                    }, {
                        "title": "Poland",
                        "id": "PL",
                    }, {
                        "title": "Romania",
                        "id": "RO",
                    }, {
                        "title": "Bulgaria",
                        "id": "BG",
                    }, {
                        "title": "Ukraine",
                        "id": "UA",
                    }, {
                        "title": "Belarus",
                        "id": "BY",
                    }, {
                        "title": "Serbia",
                        "id": "RS",
                    }, {
                        "title": "Switzerland",
                        "id": "CH",
                    }, {
                        "title": "Norway",
                        "id": "NO",
                    }, {
                        "title": "Croatia",
                        "id": "HR",
                    }, {
                        "title": "Moldova",
                        "id": "MD",
                    }, {
                        "title": "Bosnia and Herzegovina",
                        "id": "BA",
                    }, {
                        "title": "Albania",
                        "id": "AL",
                    }, {
                        "title": "North Macedonia",
                        "id": "MK",
                    }, {
                        "title": "Montenegro",
                        "id": "ME",
                    }, {
                        "title": "Iceland",
                        "id": "IS",
                    }, {
                        "title": "Andorra",
                        "id": "AD",
                    }, {
                        "title": "Monaco",
                        "id": "MC",
                    }, {
                        "title": "Liechtenstein",
                        "id": "LI",
                    }, {
                        "title": "San Marino",
                        "id": "SM",
                    }, {
                        "title": "Holy See",
                        "id": "VA",
                    }
                ]
            },
            {
                "name": "Middle East",
                "color": am4core.color("rgba(198, 177, 102, 0.8)"),
                "data": [
                    {
                        "title": "Bahrain",
                        "id": "BH",
                    }, {
                        "title": "Cyprus",
                        "id": "CY",
                    }, {
                        "title": "Egypt",
                        "id": "EG",
                    }, {
                        "title": "Iran",
                        "id": "IR",
                    }, {
                        "title": "Iraq",
                        "id": "IQ",
                    }, {
                        "title": "Israel",
                        "id": "IL",
                    }, {
                        "title": "Jordan",
                        "id": "JO",
                    }, {
                        "title": "Kuwait",
                        "id": "KW",
                    }, {
                        "title": "Lebanon",
                        "id": "LB",
                    }, {
                        "title": "Oman",
                        "id": "OM",
                    }, {
                        "title": "Palestine",
                        "id": "PS",
                    }, {
                        "title": "Qatar",
                        "id": "QA",
                    }, {
                        "title": "Saudi Arabia",
                        "id": "SA",
                    }, {
                        "title": "Syrian Arab Republic",
                        "id": "SY",
                    }, {
                        "title": "Turkey",
                        "id": "TR",
                    }, {
                        "title": "United Arab Emirates",
                        "id": "AE",
                    }, {
                        "title": "Yemen",
                        "id": "YE",
                    }
                ]
            },
            {
                "name": "Russia",
                "color": am4core.color("rgba(198, 177, 102, 0.8)"),
                "data": [
                    {
                        "title": "Russia",
                        "id": "RU",
                        "customData": "2013"
                    }
                ]
            },
            {
                "name": "UK & Ireland",
                "color": am4core.color("rgba(198, 177, 102, 0.8)"),
                "data": [
                    {
                        "title": "Great Britain",
                        "id": "GB",
                    },
                    {
                        "title": "Ireland",
                        "id": "IE",
                    }
                ]
            },
            {
                "name": "USA",
                "color": am4core.color("rgba(198, 177, 102, 0.8)"),
                "data": [
                    {
                        "title": "USA",
                        "id": "US",
                    }
                ]
            }
        ];

// This array will be populated with country IDs to exclude from the world series
        var excludedCountries = ["AQ"];

// Create a series for each group, and populate the above array
        groupData.forEach(function (group) {
            let series = chart2.series.push(new am4maps.MapPolygonSeries());
            series.name = group.name;
            series.useGeodata = true;
            var includedCountries = [];
            group.data.forEach(function (country) {
                includedCountries.push(country.id);
                excludedCountries.push(country.id);
            });
            series.include = includedCountries;

            series.fill = am4core.color(group.color);

            // By creating a hover state and setting setStateOnChildren to true, when we
            // hover over the series itself, it will trigger the hover SpriteState of all
            // its countries (provided those countries have a hover SpriteState, too!).
            series.setStateOnChildren = true;
            series.calculateVisualCenter = true;

            // Country shape properties & behaviors
            var mapPolygonTemplate = series.mapPolygons.template;
            // Instead of our custom title, we could also use {name} which comes from geodata

            let name = series.name.toLowerCase().replace('&', '').replace('  ', '-').replace(' ', '-');
            let $selected = $('#' + name);
            $selected = $selected.add($selected.parent().next('.header-regions-languages').children());

            $selected.mouseover(function () {
                if ($selected.hasClass('active')) {
                    return;
                }

                series.mapPolygons.each(function (mapPolygon) {
                    mapPolygon.isHover = true;
                })

                group.isHover = false;
                group.isHover = true;
            })

            $selected.mouseout(function () {
                series.mapPolygons.each(function (mapPolygon) {
                    mapPolygon.isHover = false;
                })
            })

            if ($selected.hasClass('active')) {
                mapPolygonTemplate.fill = am4core.color(group.color);
            }

            mapPolygonTemplate.fillOpacity = 0.8;
            mapPolygonTemplate.nonScalingStroke = true;
            mapPolygonTemplate.tooltipPosition = "fixed"

            mapPolygonTemplate.events.on("over", function (event) {
                series.mapPolygons.each(function (mapPolygon) {
                    mapPolygon.isHover = true;
                })
                event.target.isHover = false;
                event.target.isHover = true;
            })

            mapPolygonTemplate.events.on("out", function () {
                series.mapPolygons.each(function (mapPolygon) {
                    mapPolygon.isHover = false;
                })
            })

            // States
            var hoverState = mapPolygonTemplate.states.create("hover");
            hoverState.properties.fill = am4core.color("rgba(48, 92, 110, 0.7)");

            // Tooltip
            // mapPolygonTemplate.tooltipText = "{title} joined EU at {customData}"; // enables tooltip
            // mapPolygonTemplate.tooltipText = "{title}"; // enables tooltip
            // series.tooltip.getFillFromObject = false; // prevents default colorization, which would make all tooltips red on hover
            // series.tooltip.background.fill = am4core.color(group.color);

            // MapPolygonSeries will mutate the data assigned to it,
            // we make and provide a copy of the original data array to leave it untouched.
            // (This method of copying works only for simple objects, e.g. it will not work
            //  as predictably for deep copying custom Classes.)
            series.data = JSON.parse(JSON.stringify(group.data));
        });

// The rest of the world.
        var worldSeries = chart2.series.push(new am4maps.MapPolygonSeries());
        worldSeries.name = "world";
        worldSeries.useGeodata = true;
        worldSeries.exclude = excludedCountries;
        worldSeries.fillOpacity = 0.8;
        worldSeries.hiddenInLegend = true;
        worldSeries.mapPolygons.template.nonScalingStroke = true
    }

// // This auto-generates a legend according to each series' name and fill
//     chart2.legend = new am4maps.Legend();
//
// // Legend styles
//     chart2.legend.paddingLeft = 27;
//     chart2.legend.paddingRight = 27;
//     chart2.legend.marginBottom = 15;
//     chart2.legend.width = am4core.percent(90);
//     chart2.legend.valign = "bottom";
//     chart2.legend.contentAlign = "left";

// Legend items
//     chart2.legend.itemContainers.template.interactionsEnabled = false;

    /**
     * ---------------------------------------
     * This demo was created using amchart_reside   s 4.
     *
     * For more information visit:
     * https://www.amchart_residentials.com/
     *
     * Documentation is available at:
     * https://www.amchart_residentials.com/docs/v4/
     * ---------------------------------------
     */


    if ($('#chart-residential-div').get(0) !== undefined) {

        const locale = $('#chart-residential-div').get(0).dataset.locale;

        const city = [
            {
                "en": "London",
                "ru": "Лондон"
            },
            {
                "en": "New York",
                "ru": "Нью-Йорк"
            },
            {
                "en": "Oxford",
                "ru": "Оксфорд"
            },
            {
                "en": "Monaco",
                "ru": "Монако"
            },
            {
                "en": "Moscow",
                "ru": "Москва"
            },
            {
                "en": "Dubai",
                "ru": "Дубай"
            },
            {
                "en": "Abu Dhabi",
                "ru": "Абу-Даби"
            },
            {
                "en": "Italy",
                "ru": "Италия"
            },
            {
                "en": "Maldives",
                "ru": "Мальдивы"
            },
            {
                "en": "Pakistan",
                "ru": "Пакистан"
            },
            {
                "en": "Lagos",
                "ru": "Лагос"
            },
            {
                "en": "Ibiza",
                "ru": "Ибица"
            },
            {
                "en": "Latvia",
                "ru": "Латвия"
            },
            {
                "en": "Paris",
                "ru": "Париж"
            },
        ]

// Create map instance
        var chart_residential = am4core.create("chart-residential-div", am4maps.MapChart);
        chart_residential.seriesContainer.draggable = false;
        chart_residential.seriesContainer.resizable = false;
// Set map definition
        chart_residential.geodata = am4geodata_worldLow;

// Set projection
        chart_residential.projection = new am4maps.projections.Miller();


// Create map polygon series
        var polygonSeries_residential = chart_residential.series.push(new am4maps.MapPolygonSeries());

        var polygonTemplate_residential = polygonSeries_residential.mapPolygons.template;
        polygonTemplate_residential.fill = am4core.color("#DCD2EC");


// Make map load polygon (like country names) data from GeoJSON
        polygonSeries_residential.useGeodata = true;

// Remove Antarctica
        polygonSeries_residential.exclude = ["AQ"];

// Create image series
        var imageSeries2 = chart_residential.series.push(new am4maps.MapImageSeries());

        var imageSeriesTemplate2 = imageSeries2.mapImages.template;
        var circle2_residential = imageSeriesTemplate2.createChild(am4core.Circle);
        circle2_residential.radius = 10;
        circle2_residential.fill = am4core.color("#FFFFFF");
        circle2_residential.stroke = am4core.color("#305c6e");
        circle2_residential.strokeWidth = 8.5;
        circle2_residential.strokeOpacity = 0.4;
        circle2_residential.nonScaling = true;
        circle2_residential.tooltipText = "{title}";


// Set property fields
        imageSeriesTemplate2.propertyFields.latitude = "latitude";
        imageSeriesTemplate2.propertyFields.longitude = "longitude";// Set property fields


// Create image series
        var imageSeries_residential = chart_residential.series.push(new am4maps.MapImageSeries());

// Create a circle image in image series template so it gets replicated to all new images
        var imageSeriesTemplate = imageSeries_residential.mapImages.template;
        var circle_residential = imageSeriesTemplate.createChild(am4core.Circle);
        circle_residential.radius = 3;
        circle_residential.fill = am4core.color("#FFFFFF");
        circle_residential.stroke = am4core.color("#7C98A4");
        circle_residential.strokeWidth = 3;
        circle_residential.nonScaling = true;
        circle_residential.tooltipText = "{title}";
        circle_residential.tooltipText.background = am4core.color("#67b7dc");

// Set property fields
        imageSeriesTemplate.propertyFields.latitude = "latitude";
        imageSeriesTemplate.propertyFields.longitude = "longitude";// Set property fields


// Add data for the three cities
        imageSeries_residential.data = [{
            "latitude": 51.509865,
            "longitude": -0.118092,
            "title": city[0][locale]
        }, {
            "latitude": 40.712775,
            "longitude": -74.005973,
            "title": city[1][locale]
        }, {
            "latitude": 51.752022,
            "longitude": -1.257677,
            "title": city[2][locale]
        }, {
            "latitude": 43.733334,
            "longitude": 7.416667,
            "title": city[3][locale]
        }, {
            "latitude": 55.751244,
            "longitude": 37.618423,
            "title": city[4][locale]
        }, {
            "latitude": 25.276987,
            "longitude": 55.296249,
            "title": city[5][locale]
        }, {
            "latitude": 24.466667,
            "longitude": 54.366669,
            "title": city[6][locale]
        }, {
            "latitude": 41.29246,
            "longitude": 12.573610,
            "title": city[7][locale]
        }, {
            "latitude": 1.924992,
            "longitude": 73.399658,
            "title": city[8][locale]
        }, {
            "latitude": 33.738045,
            "longitude": 73.084488,
            "title": city[9][locale]
        }, {
            "latitude": 6.465422,
            "longitude": 3.406448,
            "title": city[10][locale]
        }, {
            "latitude": 38.906986,
            "longitude": 1.421416,
            "title": city[11][locale]
        }, {
            "latitude": 56.946285,
            "longitude": 24.105078,
            "title": city[12][locale]
        }, {
            "latitude": 48.856614,
            "longitude": 2.352222,
            "title": city[13][locale]
        }
        ];
// Add data for the three cities
        imageSeries2.data = imageSeries_residential.data;
    }

    for (let i = 0; i < 4; i++) {

        if ($('#chart-get-in-touch-div-' + i).get(0) !== undefined) {

            // Create map instance
            var chart_get_in_touch = am4core.create("chart-get-in-touch-div-" + i, am4maps.MapChart);
            chart_get_in_touch.seriesContainer.resizable = false;
            chart_get_in_touch.seriesContainer.draggable = false;
            chart_get_in_touch.maxZoomLevel = 1.7;
            chart_get_in_touch.minZoomLevel = 1.7;


// Set map definition
            chart_get_in_touch.geodata = am4geodata_continentsRussiaEuropeHigh;

// Set projection
            chart_get_in_touch.projection = new am4maps.projections.Miller();

            chart_get_in_touch.homeZoomLevel = 1.7;
            chart_get_in_touch.homeGeoPoint = {
                latitude: 40,
                longitude: 75
            };

// Create map polygon series
            var polygonSeries_get_in_touch = chart_get_in_touch.series.push(new am4maps.MapPolygonSeries());

            var polygonTemplate_get_in_touch = polygonSeries_get_in_touch.mapPolygons.template;
            polygonTemplate_get_in_touch.fill = am4core.color("#DCD2EC");


// Make map load polygon (like country names) data from GeoJSON
            polygonSeries_get_in_touch.useGeodata = true;

// Remove Antarctica
            polygonSeries_get_in_touch.exclude = ["northAmerica", "africa", 'antarctica', 'oceania', 'southAmerica'];

// Create image series
            imageSeries = chart_get_in_touch.series.push(new am4maps.MapImageSeries());

            imageSeriesTemplate = imageSeries.mapImages.template;
            // Set property fields
            imageSeriesTemplate.propertyFields.latitude = "latitude";
            imageSeriesTemplate.propertyFields.longitude = "longitude";
            imageSeriesTemplate.tooltipText = "{title}";
            imageSeriesTemplate.tooltipText.background = am4core.color("#67b7dc");
            imageSeriesTemplate.nonScaling = true;
            imageSeriesTemplate.fill = am4core.color("#FFFFFF");

            // imageSeriesTemplate.events.on("hit", function (ev) {
            //     var target = ev.target.dataItem.dataContext.title;
            //     $('.office-details').attr('hidden', true);
            //     $('#office-' + target).attr('hidden', false);
            // }, this);

            var circle1_get_in_touch = imageSeriesTemplate.createChild(am4core.Circle);
            circle1_get_in_touch.radius = 10;
            circle1_get_in_touch.strokeWidth = 8.5;
            circle1_get_in_touch.strokeOpacity = 0.4;
            circle1_get_in_touch.propertyFields.stroke = "color2";

// Create a circle image in image series template so it gets replicated to all new images
            var circle2_get_in_touch = imageSeriesTemplate.createChild(am4core.Circle);
            circle2_get_in_touch.radius = 3;
            circle2_get_in_touch.strokeWidth = 3;
            circle2_get_in_touch.propertyFields.stroke = "color1";

            var currentLocation = $('#chart-get-in-touch-div-' + i).data('office');

            var cityData = [{
                "latitude": 51.509865,
                "longitude": -0.118092,
                "title": "UK",
            }, {
                "latitude": 43.733334,
                "longitude": 7.416667,
                "title": "Monaco",
            }, {
                "latitude": 55.751244,
                "longitude": 37.618423,
                "title": "Russia",
            }, {
                "latitude": 24.466667,
                "longitude": 54.366669,
                "title": "UAE",
            }
            ];

            if (currentLocation === 'UK') {
                [cityData[0], cityData[1]] = [cityData[1], cityData[0]];
            }

            cityData.map((element) => {
                element.color1 = element.title === currentLocation ? am4core.color("#C6B166") : am4core.color("#7C98A4");
                element.color2 = element.title === currentLocation ? am4core.color("#C6B166") : am4core.color("#305c6e");
                return element;
            })

            imageSeries.data = cityData;
        }
    }

// Create map instance
    if ($('#globe').get(0) !== undefined) {

        var chart = am4core.create("globe", am4maps.MapChart);

// Set map definition
        chart.geodata = am4geodata_worldHigh;

// Set projection
        chart.projection = new am4maps.projections.Orthographic();
        chart.panBehavior = "rotateLongLat";
        // chart.deltaLatitude = -25; // setezi longitudinea initiala !!
        // chart.deltaLongitude = -55;

        chart.padding(20, 20, 20, 20);

        // limits vertical rotation
        chart.adapter.add("deltaLatitude", function (delatLatitude) {
            return am4core.math.fitToRange(delatLatitude, -90, 90);
        })

// Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

// Exclude Antartica
//         polygonSeries.exclude = ["AQ"];

// Make map load polygon (like country names) data from GeoJSON
        polygonSeries.useGeodata = true;

// Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        // polygonTemplate.polygon.fillOpacity = 0.8;
        polygonTemplate.fill = am4core.color("rgba(48, 92, 110, 0.2)");
        polygonTemplate.stroke = am4core.color("rgba(69,74,88,0.2)");
        polygonTemplate.strokeWidth = 0.2;

        var graticuleSeries = chart.series.push(new am4maps.GraticuleSeries());
        graticuleSeries.mapLines.template.line.stroke = am4core.color("#ffffff");
        graticuleSeries.mapLines.template.line.strokeOpacity = 0.08;
        graticuleSeries.fitExtent = false;

        chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1;
        chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#ffffff");

// Create hover state and set alternative fill color
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("rgba(48, 92, 110, 0.3)");

// Add image series
        var imageSeries = chart.series.push(new am4maps.MapImageSeries());
        imageSeries.mapImages.template.propertyFields.longitude = "longitude";
        imageSeries.mapImages.template.propertyFields.latitude = "latitude";
        imageSeries.mapImages.template.tooltipText = "{title}";
        imageSeries.mapImages.template.propertyFields.url = "url";

        var circle = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle.radius = 3;
        circle.propertyFields.fill = "color";
        circle.nonScaling = true;

        var circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle2.radius = 3;
        circle2.propertyFields.fill = "color";


        circle2.events.on("inited", function (event) {
            animateBullet(event.target);
        })


        function animateBullet(circle) {
            var animation = circle.animate([{
                property: "scale",
                from: 1 / chart.zoomLevel,
                to: 5 / chart.zoomLevel
            }, {property: "opacity", from: 1, to: 0}], 1000, am4core.ease.circleOut);
            animation.events.on("animationended", function (event) {
                animateBullet(event.target.object);
            })
        }

        let animation;

        setTimeout(function () {
            animation = chart.animate({property: "deltaLongitude", to: 100000}, 20000000);
        }, 1000)

        chart.seriesContainer.events.on("down", function () {
            if (animation) {
                animation.stop();
            }

            chart.zoomToRectangle(
                chart.north,
                chart.east,
                chart.south,
                chart.west,
                1,
                true
            );
        })

        var colorSet = new am4core.ColorSet();

        imageSeries.data = [{
            "title": "London",
            "latitude": 51.509865,
            "longitude": -0.118092,
            "color": colorSet.next()
        }, {
            "title": "Oxford",
            "latitude": 51.752022,
            "longitude": -1.257677,
            "color": colorSet.next()
        }, {
            "title": "Abu dhabi",
            "latitude": 24.466667,
            "longitude": 54.366669,
            "color": colorSet.next()
        }, {
            "title": "Dubai",
            "latitude": 25.276987,
            "longitude": 55.296249,
            "color": colorSet.next()
        }, {
            "title": "Moscow",
            "latitude": 55.751244,
            "longitude": 37.618423,
            "color": colorSet.next()
        }, {
            "title": "Seoul",
            "latitude": 37.532600,
            "longitude": 127.024612,
            "color": colorSet.next()
        }];

        $('.location-header-title').click(function () {
            let found = imageSeries.data.find(element => element.title === $(this).data('name'));

            chart.animate({
                property: "deltaLongitude",
                to: -found.longitude
            }, 2000);

            chart.animate({
                property: "deltaLatitude",
                to: 0
            }, 2000);

            setTimeout(function () {
                animation = chart.animate({property: "deltaLongitude", to: 100000}, 20000000);
            }, 5000);
        })

        /**
         * ---------------------------------------
         * This demo was created using amchart_reside   s 4.
         *
         * For more information visit:
         * https://www.amchart_residentials.com/
         *
         * Documentation is available at:
         * https://www.amchart_residentials.com/docs/v4/
         * ---------------------------------------
         */
    }
})
; // end am4core.ready()
