$(document).ready(function () {
    let $dropdown = $('.dropdown');
    $dropdown.mouseenter(function () {
        let $dropdown = $(this).find('.dropdown-menu');
        $dropdown.addClass('show');
        let height = $dropdown.height();
        // $('.header-bottom-right-triangle').css('borderBottomWidth', height * 2 / 3).css('borderLeftWidth', height / 3);
        // $('.header-top-right-triangle').css('borderTopWidth', height * 2 / 3).css('borderLeftWidth', height / 3);
        // $('.header-bottom-left-triangle').css('borderBottomWidth', height * 2 / 3).css('borderRightWidth', height / 3);
        // $('.header-top-left-triangle').css('borderTopWidth', height * 2 / 3).css('borderRightWidth', height / 3);
    });

    $dropdown.mouseleave(function () {
        $(this).find('.dropdown-menu').removeClass('show');
    });

    $('.header-regions-languages a').hover(function () {
        $(this).parent().prev('.header-regions').find('a').toggleClass('active');
    });

    $('.menu-toggle').click(function () {
        if (!$(this).attr('disabled')) {
            if(!$('nav.menu').hasClass('toggled')) {
                document.getElementsByClassName('menu-drill')[0].scrollTo(0, 0)
            }
            $('.menu-expand.active').find('.nav-back-link').click();
            $('nav.menu').toggleClass('toggled').removeClass('top');
            $('li.menu-search').removeClass('active');
            $('input.search-text').val('');
        }
    });

    $('.menu-globe, #region-close-container').click(function () {
        if ($(this).css('opacity') == 1) {
            let $regions = $('.menu-drill.menu-regions');

            $regions.toggleClass('toggled');
            $('.menu').removeClass('top');

            if (!$regions.hasClass('toggled')) {
                $('.menu-toggle').attr('disabled', false).css('opacity', 1).css('cursor', 'pointer');
                $('#region-close-container').hide();
            } else {
                document.getElementById('regionContent').scrollTo(0, 0)
                $('.menu-toggle').attr('disabled', true).css('opacity', 0).css('cursor', 'auto');
                $('#region-close-container').show();
            }
        }
    });

    $('.menu-expand').click(function () {
        $(this).addClass('active');
    });

    $('.nav-back-link').click(function (e) {
        e.stopPropagation();
        $(this).closest('.menu-expand').removeClass('active');
    });


    $('.search-close').click(function () {
        $('input.search-text').val('');
        $(this).css('opacity', 0);
        $('li.menu-search').removeClass('active');
        filter('');
    })

    $('input.search-text').keyup(function () {
        if ($(this).val() !== '') {
            $('.search-close').css('opacity', 1);
        } else {
            $('.search-close').css('opacity', 0);
        }
    })

    $('.search-text').keyup(function () {
        $('html, body').animate({ scrollTop: 0 });

        let text = $(this).val();

        if (text.length > 1) {
            $(this).closest('li.menu-search').addClass('active');
            filter(text.toUpperCase());
        } else {
            $('li.menu-search').removeClass('active');
        }
    })
});

function filter(filter) {
    let elements = $('li.menu-search').find('.nav-expand-content li').get();

    for (let i = 0; i < elements.length; i++) {
        let a = elements[i].getElementsByTagName('a')[0];
        if (a !== undefined && a.innerHTML.toUpperCase().indexOf(filter) > -1) {
            elements[i].style.display = "";
        } else {
            elements[i].style.display = "none";
        }
    }
}
